<template>
  <div class="page flex-row justify-center align-center">
    <div class="content">
      <div
        v-if="buyTimeOrderData.status == 0 && buyTimeOrderData.payType == 1"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">微信支付</span>
        <span class="money">￥{{ buyTimeOrderData.payAmount }}</span>
        <div
          class="flex-col center-info justify-center align-center"
          style="height: 357rem; margin: 34rem 0 50rem 0"
        >
          <span style="color: #a4a4a4">使用微信「扫一扫」扫码付款</span>
          <img
            v-if="buyTimeOrderData.rawUrl"
            :src="'data:image/png;base64,' + buyTimeOrderData.rawUrl"
          />
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
      <div
        v-if="buyTimeOrderData.status == 0 && buyTimeOrderData.payType == 4"
        class="success-content flex-col justify-center align-center"
      >
        <span class="wxPay-title">支付宝支付</span>
        <span class="money">￥{{ buyTimeOrderData.payAmount }}</span>
        <div
          class="flex-col center-info justify-center align-center"
          style="height: 357rem; margin: 34rem 0 50rem 0"
        >
          <span style="color: #a4a4a4">使用支付宝「扫一扫」扫码付款</span>
          <img
            v-if="buyTimeOrderData.rawUrl"
            :src="'data:image/png;base64,' + buyTimeOrderData.rawUrl"
          />
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
      <div
        v-if="buyTimeOrderData.status == 1"
        class="success-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/tick-circle.png" alt="" />
          <span class="success">充值成功</span>
        </div>
        <div class="flex-col center-info justify-center align-center">
          <span
            >本次充值屏幕：{{
              buyTimeOrderData.name | screenFilter
            }}，充值时长：{{ buyTimeOrderData.buyTime }}秒</span
          >
          <span
            >当前屏幕剩余总时长：{{
              buyTimeOrderData.buyTimeRemainTime
            }}秒</span
          >
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
      <div
        v-if="buyTimeOrderData.status == 2"
        class="cancel-content flex-col justify-center align-center"
      >
        <div class="pay-icon flex-col justify-center align-center">
          <img src="../../assets/icon/close-circle.png" alt="" />
          <span>充值失败</span>
        </div>
        <div class="flex-col center-info justify-center align-center">
          <span>很抱歉，充值出现了问题。请重新尝试！</span>
          <div class="txtServicePopover flex-row align-center">
            如果问题仍然没有得到解决，请<txtServicePopover />帮您处理
          </div>
        </div>
        <div class="btn-group flex-row">
          <div id="default-btn" class="back" @click="goBack">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { getCommonBuyTimeOrderInfo } from "@/api/request";
import { Loading } from "element-ui";
import txtServicePopover from "@/components/txtServicePopover.vue";

export default {
  components: {
    txtServicePopover,
  },
  data() {
    return {
      commonBuyTimeOrderId: null, // 临时订单id
      payTimer: null, // 初始定时器变量名为null
      buyTimeOrderData: {},
      loading: null,
    };
  },
  computed: {},
  created() {
    // 微信二维码支付
    if (this.$route.query.commonBuyTimeOrderId) {
      this.commonBuyTimeOrderId = this.$route.query.commonBuyTimeOrderId;
    }
    // 非微信二维码支付（跳转第三方支付，比如支付宝）
    if (this.$route.query.invoiceId) {
      this.commonBuyTimeOrderId = this.$route.query.invoiceId;
    }
    this.getCommonBuyTimeOrderInfo(this.commonBuyTimeOrderId, true);
    this.payTimer = setInterval(() => {
      this.getCommonBuyTimeOrderInfo(this.commonBuyTimeOrderId);
    }, 5000);
  },
  methods: {
    getCommonBuyTimeOrderInfo(commonBuyTimeOrderId, isFirst = false) {
      if (isFirst) {
        this.loading = Loading.service({
          lock: true,
          background: "rgba(255, 255, 255, 0.8)",
        });
      }
      getCommonBuyTimeOrderInfo({
        common_buy_time_order_id: commonBuyTimeOrderId,
      })
        .then((res) => {
          if (res.status !== "0") {
            this.clearTimer();
          }
          this.buyTimeOrderData = res;
          if (isFirst) {
            this.loading.close();
          }
        })
        .catch((code) => {
          if (isFirst) {
            this.loading.close();
          }
          this.clearTimer();
          // 订单不存在等需要跳转404页面的情况
          if (code == "40003" || code == "40004" || code == "10001") {
            this.$router.replace({ path: "/error" });
          } else {
            this.$router.back();
          }
        });
    },
    goBack() {
      this.$router.replace({ name: "Gold" });
    },
    clearTimer() {
      if (this.payTimer) {
        clearInterval(this.payTimer);
      }
      this.payTimer = null;
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  background: #f8f8f8;
  .content {
    width: 880rem;
    background-color: #fff;
    padding: 58rem 0 50rem 0;
    border-radius: 6rem;
    .success-content,
    .cancel-content {
      width: 100%;
      .wxPay-title {
        font-size: 28rem;
        color: #272927;
        line-height: 40rem;
      }
      .money {
        font-size: 28rem;
        color: #2bb3e3;
        line-height: 40rem;
        position: relative;
        top: 10rem;
      }
      .pay-icon {
        img {
          width: 70rem;
        }
        span {
          font-size: 28rem;
          color: #252927;
          line-height: 40rem;
          margin-top: 23rem;
        }
        .success {
          color: #2bb3e3;
        }
      }
      .center-info {
        background-color: #f8f8f8;
        width: 627rem;
        height: 138rem;
        margin: 42rem 0 40rem 0;
        border-radius: 12rem;
        span,
        .txtServicePopover {
          font-size: 18rem;
          color: #555755;
          line-height: 25rem;
        }
        span:nth-child(2),
        .txtServicePopover {
          margin-top: 8rem;
        }
        img {
          width: 220rem;
          height: 220rem;
          margin-top: 32rem;
        }
      }
      .btn-group {
        .back {
          width: 132rem;
          height: 50rem;
          background: #ffffff;
          border-radius: 25rem;
          border: 1rem solid #32c3f6;
          text-align: center;
          line-height: 50rem;
          font-size: 18rem;
          font-weight: 500;
          color: #2bb3e3;
        }
      }
    }
  }
}
</style>
